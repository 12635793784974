import React from "react";
import Emoji, { EmojiWrapper } from "./emoji";
import styled from "styled-components";
import { purple } from "./colors";

const CenteredLi = styled.li`
  display: flex;
  align-items: flex-end;
`;

export default function AboutMe() {
  return (
    <React.Fragment>
      <h1 style={{ margin: 0 }}>Matthew Gerstman</h1>
      <hr style={{ borderColor: purple, width: "500px" }} />
      <ul style={{ margin: 0 }}>
        <CenteredLi>
          <Emoji size="50px" marginRight="30px" name="bluecore.webp" />
          Staff Software Engineer at Bluecore
        </CenteredLi>
        <CenteredLi>
          <Emoji size="50px" marginRight="30px" name="dropbox.svg" />
          Prev: Four Years at Dropbox
        </CenteredLi>
        <CenteredLi>
          <EmojiWrapper fontSize="0.75em" size="50px" marginRight="30px">
            🗽
          </EmojiWrapper>{" "}
          NYC
        </CenteredLi>
        <CenteredLi>
          <Emoji size="50px" marginRight="30px" name="linkedin.png" />
          <a href="https://linkedin.com/in/matthewgerstman/">linkedin.com/in/matthewgerstman</a>
        </CenteredLi>
        <CenteredLi>
        <EmojiWrapper fontSize="0.75em" size="50px" marginRight="30px">
          🧾
          </EmojiWrapper>
          <a href="https://bit.ly/resume-mtg">bit.ly/resume-mtg</a>
        </CenteredLi>        
        <CenteredLi>
          <EmojiWrapper fontSize="0.75em" size="50px" marginRight="30px">
            📒
          </EmojiWrapper>
          <a href="https://staff.matthewgerstman.com">
            staff.matthewgerstman.com
          </a>
        </CenteredLi>
      </ul>
    </React.Fragment>
  );
}
