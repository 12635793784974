import { Appear, Notes } from "mdx-deck";
import React from "react";
import classNames from "classnames";

const dev = false;
export function AppearList({ children, className, style }) {
  return (
    <>
      <ul style={style} className={classNames("appear-list", className)}>
        {dev ? children : <Appear>{children}</Appear>}
      </ul>
      <Notes>
        <ul>{children}</ul>
      </Notes>
    </>
  );
}
