import styled from "styled-components";

export const black = "#000000";
export const purple = "#561d8c";

export default styled.div(
	[],
	`
  backgroundColor: ${black} !important,
  color: "white"
`
);
