import React, { Fragment } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useDeck } from "gatsby-theme-mdx-deck";
import Footer from "./footer";
import { FOOTER_HEIGHT } from "./constants";

const NationalWebBold = require("../assets/fonts/NationalWeb-Bold.woff2");
const NationalWebSemibold = require("../assets/fonts/NationalWeb-Semibold.woff2");
const NationalWebLight = require("../assets/fonts/NationalWeb-Light.woff2");
console.log({ NationalWebLight });

const footerHeight = FOOTER_HEIGHT;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "NationalWebBold";
    src: url('${NationalWebBold}') format("truetype");
  }

  @font-face {
    font-family: "NationalWebSemibold";
    src: url('${NationalWebSemibold}') format("truetype");
  }

  @font-face {
    font-family: "NationalWebLight";
    src: url('${NationalWebLight}') format("truetype");
  }

  body {
    font-family: "NationalWebSemibold";
  }

  a {
		color: white;
		text-decoration: none;
	}

	ul {
		list-style: none;
		text-align: left;
		font-size: 1.5em;
	}

  ul {
    list-style: disc;
    width: 80%;
  }

	li {
		padding-top: 10px;
	}
	h1 {
    font-family: NationalWebBold!important;    
    text-align: center;    
  }

  h2 {
    font-family: NationalWebBold!important;    
    text-align: center;
  }

  .left-right {
	  width: 80%;
    display: flex;
    justify-content: space-evenly;
  }
  .left-right ul {        	  
    margin-block-start: 0;
  }
  .left-right img {
    margin-left: 40px;
  }

  img {
    object-fit: contain!important;
  }
`;

const Wrapper = styled.main`
  width: 100vw;
  height: calc(100vh - ${footerHeight});
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

const Bar = styled.div`
  background: #0953bf;
  height: 2px;
  width: ${(props) => 100 * props.size}%;
  position: fixed;
  bottom: ${footerHeight};
`;

export default function MatthewProvider({ children }) {
  const { mode, length, index } = useDeck();
  if (mode.toLowerCase() !== "normal") {
    return (
      <Fragment>
        <GlobalStyle />
        {children}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <GlobalStyle />
      <Wrapper>{children}</Wrapper>
      <Bar size={(index + 1) / length} />
      <Footer height={FOOTER_HEIGHT} />
    </Fragment>
  );
}
