import { AppearList } from "../../../../../../src/appear-list";
import { Notes } from "mdx-deck";
import AboutMe from "../../../../../../src/about-me";
import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import braid from "../../../../../../assets/simple-made-easy/braid.png";
import rich from "../../../../../../assets/simple-made-easy/rich-hickey.png";
import complected from "../../../../../../assets/simple-made-easy/complected-modules.png";
import composeSlide from "../../../../../../assets/simple-made-easy/compose-slide.png";
import compose from "../../../../../../assets/simple-made-easy/compose.png";
import modularity from "../../../../../../assets/simple-made-easy/modularity.png";
import trueModularitySlide from "../../../../../../assets/simple-made-easy/true-modularity-slide.png";
import trueModularity from "../../../../../../assets/simple-made-easy/true-modularity.png";
import componentsImg from "../../../../../../assets/docs/components.png";
import nextserver from "../../../../../../assets/docs/nextserver.png";
import orval from "../../../../../../assets/docs/orval.png";
import nextserverDemo from "../../../../../../assets/docs/nextserver-demo.gif";
import gaeRequest from "../../../../../../assets/docs/gae-request.png";
import limboRequest from "../../../../../../assets/docs/limbo-request.png";
import hamiltonWriting from "../../../../../../assets/gif/hamilton-writing.gif";
import lightReading from "../../../../../../assets/gif/light-reading.gif";
import legos from "../../../../../../assets/misc/legos.png";
import sonic from "../../../../../../assets/misc/sonic.gif";
import hermione from "../../../../../../assets/gif/hermione.gif";
import thankYou from "../../../../../../assets/gif/thank-you.gif";
import CoveredTopics from "../../../../../../src/covered";
import duskOwl from "../../../../../../src/dusk-owl";
import TallImage from "../../../../../../src/tall-image";
import "prismjs/components/prism-protobuf";
import Emoji, { EmojiWrapper } from "../../../../../../src/emoji";
import { nightOwl, github, vsDark, dracula, duotoneDark, duotoneLight, oceanicNext, shadesOfPurple, ultramin } from "@code-surfer/themes";
import customTheme from "../../../../../../src/theme";
import React from 'react';
export default {
  AppearList: AppearList,
  Notes: Notes,
  AboutMe: AboutMe,
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  braid: braid,
  rich: rich,
  complected: complected,
  composeSlide: composeSlide,
  compose: compose,
  modularity: modularity,
  trueModularitySlide: trueModularitySlide,
  trueModularity: trueModularity,
  componentsImg: componentsImg,
  nextserver: nextserver,
  orval: orval,
  nextserverDemo: nextserverDemo,
  gaeRequest: gaeRequest,
  limboRequest: limboRequest,
  hamiltonWriting: hamiltonWriting,
  lightReading: lightReading,
  legos: legos,
  sonic: sonic,
  hermione: hermione,
  thankYou: thankYou,
  CoveredTopics: CoveredTopics,
  duskOwl: duskOwl,
  TallImage: TallImage,
  Emoji: Emoji,
  EmojiWrapper: EmojiWrapper,
  nightOwl: nightOwl,
  github: github,
  vsDark: vsDark,
  dracula: dracula,
  duotoneDark: duotoneDark,
  duotoneLight: duotoneLight,
  oceanicNext: oceanicNext,
  shadesOfPurple: shadesOfPurple,
  ultramin: ultramin,
  customTheme: customTheme,
  React: React
};